import React, { useEffect } from 'react';
import Gallery360 from '../components/Gallery360';
import Selector from '../components/Selector';
import { useSelector, useDispatch } from 'react-redux';
import { getColors, setColorLocation, setFilteredColors, setSelectedColor } from '../redux/colorSelectorSlice';

const ColorSelector = () => {
    const dispatch = useDispatch();
    
    const btn = document.querySelector(".color-selector-filters-mobile__button")
    const close = document.querySelector(".color-selector-filters-mobile__modal_close")
    const modal = document.querySelector(".color-selector-filters-mobile__modal")
    const tabsList = document.querySelector('.color-selector-tabs-list_mobile');
    const title = useSelector(state => state.colorSelector.title);
    const text = useSelector(state => state.colorSelector.text);
    const colors = useSelector(state => state.colorSelector.colors);
    const colorLocation = useSelector(state => state.colorSelector.colorLocation);

    const hasInternalColors = colors.some((color) => color.color_type === "internal");

    const handleActiveTab = (e) => {
        dispatch(setColorLocation(e.target.dataset.tab));
        const filteredColors = colors.filter(color => color.color_type === e.target.dataset.tab);
        dispatch(setSelectedColor(filteredColors[0]?.id));
    }


    btn?.addEventListener("click", () => {
        modal.style.display = "flex";
    })

    close?.addEventListener("click", () => {
        modal.style.display = "none";
    })
    
    tabsList?.addEventListener('click', (e) => {
        e.preventDefault();
        modal.style.display = "none";
    
    });
    

    return (
        <section>
            <div className="main_container_360 wrapper">
                <div className="main_container_360_text">
                    <span>
                        <p className="title"><strong>{title}</strong></p>
                        <div className="line"></div>
                    </span>
                    <p className="description_desktop">{text}</p>
                </div>

                <div className="selector_colors_container" style={{ display: colorLocation === "external" ? "block" : "none" }}>
                    <Gallery360 />
                    <img src="/static/website/images/color-selector/Rotador.png" alt='' className="rotation" />
                </div>

                <div className="interior selector_colors_container" style={{ display: colorLocation === "internal" ? "block" : "none" }}>
                    <Gallery360 />
                </div>

                <nav className="tabs-buttons tabs-360">
                    <ul className="tabs-list" role="tablist">
                        <li data-tab="external" className="tabs-list__item --text-m" role="presentation" onClick={(e) => handleActiveTab(e)}><a role="tab" id="tab-2" aria-controls="tabpanel-2" href="#exterior" className="tabs-list__anchor">exterior</a></li>
                        {hasInternalColors ? (
                            <li data-tab="internal" className="tabs-list__item --text-m" role="presentation" onClick={(e) => handleActiveTab(e)}><a role="tab" id="tab-1" aria-controls="tabpanel-1" href="#interior" className="tabs-list__anchor">interior</a></li>
                        ) : ""}
                    </ul>

                    <div className='color-selector-filters-mobile'>
                        <button className="color-selector-filters-mobile__button">
                            <span className="color-selector-selected-option">exterior</span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 9L12 15L18 9" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>

                        <div className="color-selector-filters-mobile__modal">
                            <div className='color-selector-filters-mobile__modal__content'>
                                <span className="modal-close">
                                    <h5>filtrar por:</h5>
                                    <div className='color-selector-filters-mobile__modal_close'>✕</div>
                                </span>

                                <ul className="color-selector-tabs-list_mobile" role="tablist">
                                    <li data-tab="external" className="tabs-list__item --text-m" role="presentation" onClick={(e) => handleActiveTab(e)}><a role="tab" id="tab-2" aria-controls="tabpanel-2" href="#exterior" className="tabs-list__anchor">exterior</a></li>
                                    <li data-tab="internal" className="tabs-list__item --text-m" role="presentation" onClick={(e) => handleActiveTab(e)}><a role="tab" id="tab-1" aria-controls="tabpanel-1" href="#interior" className="tabs-list__anchor">interior</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>

                <Selector />
                <p className="description_mobile">{text}</p>
            </div>
        </section>
    );
}

export default ColorSelector;
