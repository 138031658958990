import React from "react";
import Rotation from "react-rotation";
import { useSelector } from "react-redux";

const Gallery360 = () => {
    const images = useSelector((state) => state.colorSelector.images);
    // console.log(images)

    return (
        <div className="slider_360_container">
            <Rotation cycle scroll={false} reverse>
                {images?.map((image) => (
                    <div className="item_360" key={image.image.url}>
                        <img src={image.image.url} />
                    </div>
                ))}
            </Rotation>
            {/* <Rotation cycle scroll={false} reverse>
                {selectedColor.fullcirclegallery && selectedColor.fullcirclegallery.images.map((image, i) => (
                    <div className="item-360" key={`color-${selectedColor.id}-image-${i}`}>
                        <img key={`color-${selectedColor.id}-image-${i}`} src={image.image.url} alt={image.image.alt} />
                    </div>
                ))}
            </Rotation> */}
        </div>
    );
};

export default Gallery360;
