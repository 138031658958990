import React, { useEffect } from 'react';
import ColorSelector from './pages/ColorSelector';
import { useDispatch } from 'react-redux';
import { setVersionId, setVersionName, setTitle, setText, getColors } from './redux/colorSelectorSlice';

const App = ({ versionId, versionName, title, text }) => {
    const dispatch = useDispatch();

    const setVersion = () => {
        dispatch(setVersionId(versionId));
    }

    const setVersionN = () => {
        dispatch(setVersionName(versionName));
    }

    const setTitleVersion = () => {
        dispatch(setTitle(title));
    }

    const setTextVersion = () => {
        dispatch(setText(text));
    }

    const setColorsVersion = () => {
        dispatch(getColors(versionId));
    }

    useEffect(() => {
        setVersion();
        setVersionN();
        setTitleVersion();
        setTextVersion();
        setColorsVersion();
    }, []);

    return (
        <ColorSelector />
    );
}

export default App;