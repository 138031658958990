import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app";
import { store } from "./redux/store";
import { Provider } from "react-redux";

const element = document.getElementById("color-selector-root");
const root = element ? ReactDOM.createRoot(element) : null;

if (root) {
    const versionId = root._internalRoot.containerInfo.dataset.versionId;
    const versionName = root._internalRoot.containerInfo.dataset.versionName;
    const title = root._internalRoot.containerInfo.dataset.title;
    const text = root._internalRoot.containerInfo.dataset.text;
    const colors = root._internalRoot.containerInfo.dataset.colors;

    // const versionId = "UX 250h";
    // const title = "Aventuras urbanas en un paquete híbrido";
    // const text = "La UX 250h combina un motor de gasolina de cuatro cilindros y 2.0 litros con un motor eléctrico auto recargable para brindar 181 caballos de fuerza con un mínimo consumo de combustible.";
    // const images = [
    //     {
    //         url: '/static/website/images/color-selector/frente.png',
    //     },
    //     {
    //         url: '/static/website/images/color-selector/oblicuofrente1.png',
    //     },
    //     {
    //         url: '/static/website/images/color-selector/lateral1.png',
    //     },
    //     {
    //         url: '/static/website/images/color-selector/oblicuoatras1.png',
    //     },
    //     {
    //         url: '/static/website/images/color-selector/atras.png',
    //     },
    //     {
    //         url: '/static/website/images/color-selector/oblicuoatras2.png',
    //     },
    //     {
    //         url: '/static/website/images/color-selector/lateral2.png',
    //     },
    //     {
    //         url: '/static/website/images/color-selector/oblicuofrente2.png',
    //     },
    // ]
    // const colors = [
    //     {
    //         hexcode: '#F5F6FB',
    //         name: 'Blanco',
    //         id: 1
    //     },
    //     {
    //         hexcode: '#828486',
    //         name: 'Gris oscuro',
    //         id: 2
    //     },
    //     {
    //         hexcode: '#BEC1C5',
    //         name: 'Gris',
    //         id: 3
    //     },
    //     {
    //         hexcode: '#C2C3C0',
    //         name: 'Plata',
    //         id: 4
    //     },
    //     {
    //         hexcode: '#C83930',
    //         name: 'Rojo',
    //         id: 5
    //     },
    //     {
    //         hexcode: '#45322A',
    //         name: 'Caviar',
    //         id: 6
    //     },
    //     {
    //         hexcode: '#E07546',
    //         name: 'Naranja',
    //         id: 7
    //     },
    //     {
    //         hexcode: '#747B50',
    //         name: 'Verde',
    //         id: 8
    //     },
    //     {
    //         hexcode: '#295DCF',
    //         name: 'Azul',
    //         id: 9
    //     },
    // ]

    root.render(
        <Provider store={store}>
            <App
                versionId={versionId}
                versionName={versionName}
                title={title}
                text={text}
                colors={colors}
            />
        </Provider>
    );
}
