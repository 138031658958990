import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setImages, setSelectedColor } from '../redux/colorSelectorSlice';

const Selector = () => {

    const dispatch = useDispatch();

    const colorsFromStore = useSelector(state => state.colorSelector.colors);
    const colorLocation = useSelector(state => state.colorSelector.colorLocation);
    const selectedColor = useSelector(state => state.colorSelector.selectedColor);

    const colors = colorsFromStore?.filter(color => color.color_type === colorLocation);

    const handleChangeColor = color => {
        dispatch(setSelectedColor(color));
    }

    useEffect(() => {
        dispatch(setImages())
    }, [selectedColor])

    return (
        <div className="color_container">
            <div className="colors">
                {colors?.map(color => (
                    <div className="color_item" key={`color-${color.id}`}>
                        <button className={selectedColor.id === color.id ? "active" : ""} onClick={() => handleChangeColor(color.id)}>
                            <span className="circle" style={{ backgroundColor: `#${color.hexcode}` }}></span>
                        </button>
                    </div>
                ))}
            </div>
            <small>{selectedColor.name}</small>
        </div>
    )
}

export default Selector;
